

  // StpageFlip
  document.addEventListener('DOMContentLoaded', function() {
    const pageFlip = new St.PageFlip(
        document.getElementById("flipBook"),{
            width: 550, // base page width
            height: 760, // base page height

            size: "stretch",
            // set threshold values:
            minWidth: 315,
            maxWidth: 1000,
            minHeight: 420,
            maxHeight: 1350,

            maxShadowOpacity: 0.5, // Half shadow intensity
            showCover: true,
            mobileScrollSupport: true, // disable content scrolling on mobile devices
            autoSize:true,
        }
    );

    // load pages
    pageFlip.loadFromHTML(document.querySelectorAll(".page"));

    document.querySelector(".btn-prev").addEventListener("click", () => {
        pageFlip.flipPrev();
    });

    document.querySelector(".btn-next").addEventListener("click", () => {
        pageFlip.flipNext();
    });
  });

$(function() {

  // 以下為 Cookies 記錄在當前瀏覽器僅顯示一世
  if(!sessionStorage.getItem("agreeterm")){
    $('.cookies').stop(true).addClass('display');
  }

  $(".close-cookies").on('click',function(e) {
    e.preventDefault();
    sessionStorage.setItem("agreeterm", true);
    $(".cookies").addClass("close-cookies");
  });

  // 以下為Swiper設定
 
   // 共用Auto 
   var auplayFun = {
     delay: 3500,
     //stopOnLastSlide: false,
     disableOnInteraction: false,
   }
 
   // 首頁Banner
   if($('.swiper-bannerIn').length > 0) {
     var swiper_bannerIn = new Swiper(".swiper-bannerIn .swiper-container", {
      autoplay: auplayFun,
      loop: true,
      centeredSlides : true,
      slidesPerView:'auto',
      grabCursor: true,
      speed: 1000,
      pagination: {
        el: ".swiper-bannerIn .swiper-pagination",
        clickable: true,
      },
      reverseDirection: true,
      autoplay: auplayFun, //auplayFun
     });
   }

  //  首頁當季推薦
  var indexRecommendSwiper = new Swiper('.index-pageRecommend-swiper .mySwiper', {
    // slidesPerView: 5,
    // spaceBetween: 30,
    centeredSlides: true,
    // centeredSlidesBounds: true,
    loop:true,
    autoplay: auplayFun, //auplayFun

    // pagination: {
    //   el: ".index-pageRecommend-swiper .swiper-pagination",
    //   clickable: true,
    // },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 2,
        spaceBetween: 30
      },
      760: {  //当屏幕宽度大于等于760
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 5,
        spaceBetween: 30,
      }
    },

    on: {
      init: function () {
        $('.swiper-slide').addClass('changed');
      },
      slideChangeTransitionStart : function() {
        $('.swiper-slide').addClass('changing');
        $('.swiper-slide').removeClass('changed');
      },
      slideChangeTransitionEnd : function() {
        $('.swiper-slide').removeClass('changing');
        $('.swiper-slide').addClass('changed');
      }
    },

  });
  
  // 客製化服務 mutiple
  var indexCustomerSwiper = [];
  if($('.index-pageCustomer-infoSwiper').length > 0) {
    [].forEach.call(document.querySelectorAll('.index-pageCustomer-infoSwiper'), function(e, index) {
      let mySwiperName = 'mySwiper'+index.toString();
      let paginationNamne = 'swiper-pagination'+index.toString();

      e.querySelector('.mySwiper').setAttribute('id', mySwiperName);
      e.querySelector('.swiper-pagination').setAttribute('id', paginationNamne);
      console.log(e.querySelectorAll('.swiper-slide').length);
      
      indexCustomerSwiper.push(new Swiper("#"+mySwiperName, {
        pagination: {
          el: "#"+paginationNamne,
          clickable: true,
        },
      }));
    })
  }

  // 產品詳細
  var Prod_mySwiper = new Swiper(".Prod-photo-small .Prod_mySwiper", {
    // autoplay: auplayFun,
    autoplay:false,
    freeMode: true,
    grabCursor : true,
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 3,
        spaceBetween: 5,
      },
      768: {  //当屏幕宽度大于等于768
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 4,
        spaceBetween: 20,
      }
    }
  });

  var Prod_mySwiper2 = new Swiper(".Prod-photo .Prod_mySwiper2", {
    // autoplay: auplayFun,
    autoplay:false,
    speed: 1000,
    spaceBetween: 10,
    navigation: {
      nextEl: ".Prod-photo .swiper-button-next",
      prevEl: ".Prod-photo .swiper-button-prev",
    },
    thumbs: {
      swiper: Prod_mySwiper,
    },

    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        spaceBetween: 0,
      },
      760: {  //当屏幕宽度大于等于760
        spaceBetween: 10,
      }
    }
  });

  var Prod_cardSwiper = new Swiper(".Prod-conOther-card .mySwiper", {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: ".Prod-conOther-card .swiper-button-next",
      prevEl: ".Prod-conOther-card .swiper-button-prev",
    },
    
    pagination: {
      el: ".Prod-conOther-card .swiper-pagination",
      type: 'fraction',
      clickable: true,
    },

    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 2,
        spaceBetween: 5
      },
      760: {  //当屏幕宽度大于等于760
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 30,
      }
    },
  });

  // 產品詳細End
   
  // 打開搜尋bar
  $('.toggle-search').on('click',function() {
    $('.search-formList').toggleClass('open');
    return false;
  });

  // 
  $('.list-group-mobile-btn').on('click',function(){
    $('.link-horizontal-wrap').toggleClass('open');
    return false;
  })
 
   // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });
 
   $(window).on("load resize scroll", function (e) {
     var scroll = $(this).scrollTop();
     var wdh = $(window).height();
     if (scroll > 50) {
         $(".float-link").addClass("active");
     } else {
         $(".float-link").removeClass("active");
     }
   });

   // BS navbar-collapse show / hidden
   $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })
 
  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });
 
   // 文字點點點
   checkEllipsis('.jsEllipsis',50);
   
   // 產品側邊選單
  var $slidemenu = $(".link-wrap.pro ul > li > a.first_menu_link");
  $slidemenu.on('click', function () {
    //=> 點擊當前項目才打開
    var serToggle = $(this).closest('li').find(".first_menu");
    $(".first_menu").not(serToggle).removeClass('open');
    $slidemenu.not($(this)).removeClass('active');

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      serToggle.removeClass('open');
    } else {
      $(this).addClass('active');
      serToggle.addClass('open');
    }

    e.stopPropagation();
  });

  if($('.sweetCancel').length > 0) {
    [].forEach.call(document.querySelectorAll('.sweetCancel'), function(e) {
      e.addEventListener('click', function(e) {
        Swal.fire({
            title: '已刪除',
            icon: 'success',
            confirmButtonText: '關閉'
        })
      });
    })
  }

  if($('.sweetInquery').length > 0) {
    document.querySelector('.sweetInquery').onclick = function(){
      Swal.fire({
          title: '商品已成功加入詢價車!',
          // text: "如欲繼續詢價，歡迎至產品頁面",
          icon: 'success',
          confirmButtonText: '前往詢價車',
          showCancelButton: true,
          cancelButtonText: "返回產品頁",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "inquery.html";
        }else{
          window.location.href = "pro_list.html";
        }
      });
    };
  }

  if($('.sweetInquery_cooperate').length > 0) {
    [].forEach.call(document.querySelectorAll('.sweetInquery_cooperate'), function(e) {
      e.addEventListener('click', function(e) {
        Swal.fire({
          title: '成功加入詢價車!',
          icon: 'success',
          confirmButtonText: '前往詢價車',
          showCancelButton: true,
          cancelButtonText: "關閉",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "inquery.html";
          }
        });
      });
    });
  }

  if($('.en_sweetCancel').length > 0) {
    [].forEach.call(document.querySelectorAll('.en_sweetCancel'), function(e) {
      e.addEventListener('click', function(e) {
        Swal.fire({
            title: 'Inquiry has been deleted',
            icon: 'success',
            confirmButtonText: 'CLOSE'
        })
      });
    })
  }

  if($('.en_sweetInquery').length > 0) {
    document.querySelector('.en_sweetInquery').onclick = function(){
      Swal.fire({
          title: 'Add to inquiry car!',
          icon: 'success',
          confirmButtonText: 'Go to inquiry car',
          showCancelButton: true,
          cancelButtonText: "Back",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "en_inquery.html";
        }else{
          window.location.href = "en_pro_list.html";
        }
      });
    };
  }

  if($('.en_sweetInquery_cooperate').length > 0) {
    [].forEach.call(document.querySelectorAll('.en_sweetInquery_cooperate'), function(e) {
      e.addEventListener('click', function(e) {
        Swal.fire({
          title: 'Go to inquiry car!',
          icon: 'success',
          confirmButtonText: 'Go to inquiry car',
          showCancelButton: true,
          cancelButtonText: "CLOSE",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "en_inquery.html";
          }
        });
      });
    });
  };

  //  自定義JS tab
  const tabsMenu = document.querySelectorAll('.js-click-item');
  const tabsPanel = document.querySelectorAll('.js-click-panel');

  tabsMenu.forEach(function(data,i){
    data.addEventListener('click',function(){
      // 先定義所有區塊隱藏
      tabsMenu.forEach(function(item){
        item.classList.remove('active')
      });
      tabsPanel.forEach(function(item){
        item.classList.add('d-none');
      });

      //當前點擊的時候..對應的索引值增加className
      tabsMenu[i].classList.add('active');
      tabsPanel[i].classList.remove('d-none');
    });

  });
 });
 
 function resize() {
   var winHeight = $(window).height();
   $(".modal-autoheight .modal-body").css({
     width: "auto",
     height: (winHeight - 200) + "px"
   });
 }
 
 // 取得頁面寬度
 function viewport() {
   var e = window,
       a = 'inner';
   if (!('innerWidth' in window)) {
       a = 'client';
       e = document.documentElement || document.body;
   }
   return { width: e[a + 'Width'], height: e[a + 'Height'] };
 }
 
 // 文字點點點
 function checkEllipsis(name,len){
   $(name).each(function(i){
     if($(this).text().length>len){
         $(this).attr("title",$(this).text());
         let text=$(this).text().substring(0,len-1)+"...";
         $(this).text(text);
     }
   });
 }
 